import React from 'react'
import styled from 'styled-components'
// @ts-ignore
import mikeHug from './assets/mike-hug.png'
import { observer } from 'mobx-react-lite'
import Section from '../../../../Core/Layout/Section/Section'
import { HtmlDiv } from '../../../../../utils/types'

const MikeHug = ({ className, ...rest }: MikeHugProps) => {
  return (
    <div className={`${className}`} {...rest}>
      <Section className="flex flex-wrap justify-around items-center my-12">
        <div>
          <img src={mikeHug} alt="Mike Dane hugging a computer" />
        </div>
        <div className="mike-hug lg:mt-0 mt-8">
          <div className="text-2xl font-bold">
            Play through the game with Mike by your side
          </div>
          <br />
          <div>
            Anyone can play through Dev Simulator on their own for free.
            <br />
            <br />
            Bootcamp students can play through with the help and guidance of one
            of YouTube’s top coding instructors.
            <br />
            <br />
            Mike will walk you through each task, show you development best
            practices, help you navigate the industry & forge you a path through
            the confusing web development landscape
          </div>
        </div>
      </Section>
    </div>
  )
}

export interface MikeHugProps extends HtmlDiv {}

export default styled(observer(MikeHug))`
  .mike-hug {
    max-width: 40rem;
  }
`
