import React from 'react'
import styled from 'styled-components'
// @ts-ignore
import appEditor from './assets/app-editor.png'
// @ts-ignore
import paths from './assets/paths.png'
import { observer } from 'mobx-react-lite'
import ChadIntroLight from './ChadIntroLight/ChadIntroLight'
import ChadIntroDark from './ChadIntroDark/ChadIntroDark'
import Section from '../../../../Core/Layout/Section/Section'
import ThemedImg from '../../../../utils/ThemedImg/ThemedImg'
import { HtmlDiv } from '../../../../../utils/types'

const TestYourSkills = ({ className, ...rest }: TestYourSkillsProps) => {
  return (
    <div className={`${className}`} {...rest}>
      <Section>
        <div className="flex flex-wrap lg:justify-between justify-center items-center">
          <div className="test-your-skills">
            <div className="text-4xl font-bold">Test Your Skills</div>
            <br />
            <div>
              Each task will challenge your skills in one or more areas of full
              stack web development.
              <br />
              <ThemedImg
                sources={[
                  { themeId: 'light', source: ChadIntroLight },
                  { themeId: 'dark', source: ChadIntroDark },
                ]}
                className="chad-intro"
              />
              <br />
              Tasks start off easy and get progressively more difficult as you
              level up.
            </div>
          </div>
          <div className="lg:mt-0 mt-8">
            <img
              src={appEditor}
              alt="a view of the Dev Simulator code editor"
            />
          </div>
        </div>
        <div className="flex flex-wrap justify-around my-12 items-center">
          <img
            src={paths}
            alt="Throughout the game, players level their avatar from 1 to 20. There's multiple paths through the game"
          />
          <div className="path-text lg:mt-0 mt-8">
            As they complete tasks and gain experience, players level their
            avatar from 0 to 20.
            <br />
            <br /> Depending on what the player wants to practice, there&apos;s
            multiple paths through the game.
            <br />
            <br /> One player might focus entirely on frontend development,
            styling and UI building, while another might go the backend route
            and build out API endpoints and work with a database.
          </div>
        </div>
      </Section>
    </div>
  )
}

export interface TestYourSkillsProps extends HtmlDiv {}

export default styled(observer(TestYourSkills))`
  .path-text {
    max-width: 40rem;
  }
  .test-your-skills {
    max-width: 35rem;
  }

  .chad-intro {
    max-width: 25rem;
    width: auto;
  }
`
