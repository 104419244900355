import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { observer } from 'mobx-react-lite'
import ThemedImg from '../../../../utils/ThemedImg/ThemedImg'
import CoworkersLight from './CoworkersLight/CoworkersLight'
import CoworkersDark from './CoworkersDark/CoworkersDark'
import SignUpPrompt from '../../../SignUpPrompt/SignUpPrompt'
import CodingRPG from './CodingRPG/CodingRPG'
import { HtmlDiv } from '../../../../../utils/types'
import Section from '../../../../Core/Layout/Section/Section'

const Banner = ({ className, ...rest }: BannerProps) => {
  return (
    <div className={`${className}`} {...rest}>
      <Section>
        <div className="flex flex-wrap lg:justify-between justify-center items-center ">
          <div>
            <div className="text-5xl font-bold coworkers-text">
              Meet your new co-workers!
            </div>
            <ThemedImg
              sources={[
                { themeId: 'light', source: CoworkersLight },
                { themeId: 'dark', source: CoworkersDark },
              ]}
              className=" w-auto coworkers-svg"
            />
          </div>
          <div className="lg:mt-0 mt-8">
            <div className="font-bold">Dev Simulator is an upcoming</div>
            <CodingRPG className="coding-rpg-svg" />
            <ul className="game-highlights px-10 py-4 rounded-2xl text-2xl font-bold bg-primary text-light m-3">
              <li>Build a full stack web app</li>
              <li>Play through a fun storyline</li>
              <li>Test your development skills</li>
              <li>Earn titles, rewards, and reach max level!</li>
            </ul>
          </div>
        </div>
        <SignUpPrompt />
      </Section>
    </div>
  )
}

export interface BannerProps extends HtmlDiv {}

export default styled(observer(Banner))`
  .coworkers-text {
    max-width: 24rem;
  }

  .game-highlights {
    max-width: 31rem;
    box-shadow: 0.25rem 0.25rem 0 ${(props) => props.theme.text.colors.bold};
  }

  .item-list {
    li {
      ${tw`my-1`}
    }
  }
  .signup-btn {
    box-shadow: 0.25rem 0.25rem 0 ${(props) => props.theme.text.colors.bold};
  }

  .coworkers-svg {
    max-width: 30rem;
  }

  .coding-rpg-svg {
    max-width: 30rem;
  }
`
