import React from 'react'
import styled from 'styled-components'
// @ts-ignore
import languageIcons from './assets/language-icons.png'
import { observer } from 'mobx-react-lite'
import Section from '../../../../Core/Layout/Section/Section'
import { HtmlDiv } from '../../../../../utils/types'

const Stack = ({ className, ...rest }: StackProps) => {
  return (
    <div className={`${className}`} {...rest}>
      <Section className="lg:px-8 my-12">
        <div className="flex flex-wrap lg:justify-between justify-center items-center p-8 bg-primary rounded-3xl primary-container ">
          <div className="languages text-light">
            <div className="text-4xl font-bold text-light">
              Use Industry Leading Technology
            </div>
            <div>
              In the game, the company will be using the extremely popular MERN
              stack for their app, so that’s what you’ll be coding in. <br />{' '}
              <br />
              MERN is widely adopted in the industry, and has huge community
              support, tutorials and plugins.
            </div>
          </div>
          <div className="language-icons bg-light rounded-xl p-6 xl:mt-0 mt-4">
            <img
              src={languageIcons}
              alt="TypeScript, Express, Node JS, React, Mongo DB"
            />
          </div>
        </div>
      </Section>
    </div>
  )
}

export interface StackProps extends HtmlDiv {}

export default styled(observer(Stack))`
  .languages {
    max-width: 39rem;
  }

  .language-icons {
    max-width: 25rem;
  }

  .primary-container {
    box-shadow: 0.25rem 0.25rem 0rem ${(props) => props.theme.text.colors.base};
  }
`
