import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { HtmlDiv } from '../../../../../utils/types'
import Section from '../../../../Core/Layout/Section/Section'

const Transform = ({ className, ...rest }: TransformProps) => {
  return (
    <div className={`${className}`} {...rest}>
      <Section className="flex flex-wrap lg:justify-between justify-center items-center p-4 py-8">
        <div className="text-2xl font-bold watch-text text-center lg:mt-0 mt-8">
          Watch as
          <br />
          Dev Simulator
          <br /> transforms
        </div>
        <div className="flex flex-col items-center lg:mt-0 mt-8">
          <div>from the</div>
          <div className="spotlight-text text-light">
            ultimate coding challenge
          </div>
          <div>to the</div>
          <div className="spotlight-text text-light">
            ultimate coding curriculum
          </div>
        </div>
        <div className="text-center font-bold lg:mt-0 mt-8">
          All for less than what
          <br />
          you&apos;ll make
          <br />
          <div className="text-primary text-2xl">PER HOUR</div>
          in the industry
        </div>
      </Section>
    </div>
  )
}

export interface TransformProps extends HtmlDiv {}

export default styled(observer(Transform))`
  .spotlight-text {
    ${tw`flex justify-center items-center text-center py-2 px-8 rounded-full text-2xl font-bold my-2`};
    background: ${(props) => props.theme.background.colors.primary.base};
    box-shadow: 0.25rem 0.25rem 0rem ${(props) => props.theme.text.colors.base};
  }
`
