import React from 'react'
import styled from 'styled-components'
// @ts-ignore
import appEditorMike from './assets/app-editor-mike.png'
import { observer } from 'mobx-react-lite'
import Section from '../../../../Core/Layout/Section/Section'
import Link from '../../../../Core/Navigation/Link/Link'
import ThemedImg from '../../../../utils/ThemedImg/ThemedImg'
import SaraIntroDark from './SaraIntroDark/SaraIntroDark'
import { HtmlDiv } from '../../../../../utils/types'
import SaraIntroLight from './SaraIntroLight/SaraIntroLight'

const LearnToCode = ({ className, ...rest }: LearnToCodeProps) => {
  return (
    <div className={`${className}`} {...rest}>
      <Section className="flex flex-wrap lg:justify-between justify-center items-center ">
        <div className="learn-to-code">
          <h2 id="bootcamp" className="text-4xl font-bold mb-0">
            Bootcamp: Learn to Code
          </h2>
          <Link
            to="/docs#bootcamp"
            className="underline text-primary font-bold"
          >
            Read full bootcamp docs
          </Link>
          <br />
          <br />
          <div>
            For a small monthly fee, players can join the{' '}
            <b>
              <i>Dev Simulator Bootcamp</i>
            </b>
            <br />
            <br />
            <ThemedImg
              sources={[
                { themeId: 'light', source: SaraIntroLight },
                { themeId: 'dark', source: SaraIntroDark },
              ]}
              className="sarah-intro"
            />
            <br />
            Bootcamp students get full video walkthroughs for each task, tons of
            mentorship content, live streams, Q&As, and learn how to code from
            the ground up.
          </div>
        </div>
        <div className="lg:mt-0 mt-8">
          <img
            src={appEditorMike}
            alt="a view of the Dev Simulator code editor"
          />
        </div>
      </Section>
    </div>
  )
}

export interface LearnToCodeProps extends HtmlDiv {}

export default styled(observer(LearnToCode))`
  .learn-to-code {
    max-width: 35rem;
  }
  .sarah-intro {
    max-width: 25rem;
    width: auto;
  }
`
