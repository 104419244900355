import React from 'react'
import styled from 'styled-components'
// @ts-ignore
import appDashboard from './assets/app-dashboard.png'
// @ts-ignore
import sprint from './assets/sprint.png'
// @ts-ignore
import epic from './assets/epic.png'
// @ts-ignore
import storyline from './assets/storyline.png'
import tw from 'twin.macro'
import { observer } from 'mobx-react-lite'
import Section from '../../../../Core/Layout/Section/Section'
import ThemedImg from '../../../../utils/ThemedImg/ThemedImg'
import { HtmlDiv } from '../../../../../utils/types'
import AlanIntroLight from './AlanIntroLight/AlanIntroLight'
import AlanIntroDark from './AlanIntroDark/AlanIntroDark'

const BuildAnApp = ({ className, ...rest }: BuildAnAppProps) => {
  return (
    <div className={`${className}`} {...rest}>
      <Section>
        <div className="flex flex-wrap lg:justify-between justify-center items-center">
          <div>
            <img
              src={appDashboard}
              alt="a view of the Dev Simulator app dashboard"
            />
          </div>
          <div className="build-an-app lg:mt-0 mt-8">
            <div className="text-4xl font-bold">Build a Real App</div>
            <br />
            <div>
              Throughout the game, you’ll work to build a full stack web app
              with your team of 8-bit co-workers.
            </div>
            <br />
            <ThemedImg
              sources={[
                { themeId: 'light', source: AlanIntroLight },
                { themeId: 'dark', source: AlanIntroDark },
              ]}
              className="alan-intro"
            />
            <br />
            <div>
              Write code in your browser to complete tasks, test your coding
              skills, and play through a fun storyline!
            </div>
          </div>
        </div>
      </Section>
      <Section className="flex flex-wrap justify-around mt-16">
        <div className="img-text">
          <img
            src={storyline}
            alt="Work with your simulated dev team to build a real app, each EPIC will add a new big feature to the codebase"
          />
          <div>
            The game&apos;s storyline revolves around <b>EPICS</b>, which follow
            the dev team as they build out a new big feature in the app.
          </div>
        </div>
        <div className="img-text">
          <img
            src={epic}
            alt="Epics are the building blocks of the story and walk through building a new big feature in the app"
          />
          <div>
            Epics contain <b>SPRINTS</b>, which implement one part of the larger
            feature.
          </div>
        </div>
        <div className="img-text">
          <img
            src={sprint}
            alt="Sprints are collections of tasks which build out part of the epic feature"
          />
          <div>
            Sprints are made up of <b>TASKS</b> which are small coding
            challenges that chain together and give the player XP.
          </div>
        </div>
      </Section>
    </div>
  )
}

export interface BuildAnAppProps extends HtmlDiv {}

export default styled(observer(BuildAnApp))`
  .img-text {
    ${tw`flex flex-col items-center text-base lg:mt-0 mt-8`}
    width: 15rem;
  }
  .build-an-app {
    max-width: 35rem;
  }
  .alan-intro {
    max-width: 25rem;
    width: auto;
  }
`
