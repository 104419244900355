import React from 'react'
import styled from 'styled-components'
import { runningOnBrowser } from '../../../../utils/helpers/helpers'
import { HtmlDiv } from '../../../../utils/types'
import ReactWavify from 'react-wavify'
import { observer } from 'mobx-react-lite'

const Wave = ({
  fill,
  height,
  numWaves,
  amplitude = 20,
  speed = 1,
  animated = false,
  className,
}: WaveProps) => {
  return runningOnBrowser() ? (
    <ReactWavify
      fill={fill}
      paused={!animated}
      options={{
        height: height,
        amplitude,
        speed,
        points: numWaves,
      }}
      className={`${className}`}
    />
  ) : (
    <></>
  )
}

export interface WaveProps extends HtmlDiv {
  fill: string
  height: number
  numWaves: number
  amplitude?: number
  speed?: number
  animated?: boolean
}

export default styled(observer(Wave))``
