import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import useApp from '../../AppContextProvider/hooks/useApp'
import { HtmlDiv } from '../../../utils/types'
import { runningOnBrowser } from '../../../utils/helpers/helpers'

const ThemedImg = ({ sources, className, ...rest }: ThemedImgProps) => {
  const app = useApp()
  const getImgSrc = () => {
    const sourceForTheme = sources.find(
      (source) => source.themeId === app.theme.id
    )
    return sourceForTheme?.source || (() => <div></div>)
  }

  const Img = getImgSrc()

  return (
    <div className={`${className}`} {...rest}>
      {runningOnBrowser() && <Img />}
    </div>
  )
}

export interface ThemedImgProps extends HtmlDiv {
  sources: {
    themeId: string
    source: React.FC
  }[]
}

export default styled(observer(ThemedImg))``
