import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { observer } from 'mobx-react-lite'
import Section from '../../../../Core/Layout/Section/Section'
import SignUpPrompt from '../../../SignUpPrompt/SignUpPrompt'
import ComeLearn from './ComeLearn/ComeLearn'
import { HtmlDiv } from '../../../../../utils/types'

const Price = ({ className, ...rest }: PriceProps) => {
  return (
    <div className={`${className}`} {...rest}>
      <Section>
        <div className="flex flex-wrap lg:justify-between justify-center items-center my-12">
          <ComeLearn className="flex-grow come-learn-svg" />
          <div className="home-price">
            <div className="text-4xl font-bold">Dev Simulator Bootcamp</div>
            <ul className="item-list">
              <li>
                Learn web development from the ground up, in an environment
                which closely resembles the industry
              </li>
              <li>
                Step through a challenge based curriculum designed from the
                ground up to teach you real world web development
              </li>
              <li>
                Watch detailed walk-through videos for every task in the game
              </li>
              <li>
                Access hours of mentorship videos, articles and live streams
              </li>
              <li>
                Join to a tight knit community of budding developers and be
                welcomed into a safe and creative space for discussion and
                growth.{' '}
              </li>
            </ul>
          </div>
        </div>
        <SignUpPrompt className="mb-8" />
      </Section>
    </div>
  )
}

export interface PriceProps extends HtmlDiv {}

export default styled(observer(Price))`
  .home-price {
    max-width: 50rem;
  }

  .come-learn-svg {
    max-width: 20rem;
    min-width: 20rem;
    width: auto;
  }

  .item-list {
    li {
      ${tw`my-4`}
    }
  }
`
