import React from 'react'
import styled from 'styled-components'
// @ts-ignore
import appForum from './assets/app-forum.png'
import { observer } from 'mobx-react-lite'
import Section from '../../../../Core/Layout/Section/Section'
import JanaIntroDark from './JanaIntroDark/JanaIntroDark'
import { HtmlDiv } from '../../../../../utils/types'
import JanaIntroLight from './JanaIntroLight/JanaIntroLight'
import ThemedImg from '../../../../utils/ThemedImg/ThemedImg'

const Community = ({ className, ...rest }: CommunityProps) => {
  return (
    <div className={`${className}`} {...rest}>
      <Section className="flex flex-wrap lg:justify-between justify-center items-center ">
        <div>
          <img
            src={appForum}
            alt="a view of the Dev Simulator community forums"
          />
        </div>
        <div className="community my-8">
          <div className="text-4xl font-bold">Join a Community of Coders</div>
          <br />
          <div>
            Access the community forums, ask questions, connect with other
            developers & maybe find a job
            <br />
            <br />
            <ThemedImg
              sources={[
                { themeId: 'light', source: JanaIntroLight },
                { themeId: 'dark', source: JanaIntroDark },
              ]}
              className="jana-intro"
            />
            <br />
            <br />
            Dev Simulator is a supportive and open community, all are welcome!
          </div>
        </div>
      </Section>
    </div>
  )
}

export interface CommunityProps extends HtmlDiv {}

export default styled(observer(Community))`
  .community {
    max-width: 35rem;
  }
  .jana-intro {
    max-width: 22rem;
    width: auto;
  }
`
