import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { HtmlDiv } from '../../../../utils/types'
import Banner from './Banner/Banner'
import HowItWorks from './HowItWorks/HowItWorks'
import BuildAnApp from './BuildAnApp/BuildAnApp'
import TestYourSkills from './TestYourSkills/TestYourSkills'
import Community from './Community/Community'
import Stack from './Stack/Stack'
import LearnToCode from './LearnToCode/LearnToCode'
import MikeHug from './MikeHug/MikeHug'
import Price from './Price/Price'
import Wave from '../../../Core/Layout/Wave/Wave'
import useApp from '../../../AppContextProvider/hooks/useApp'
import Transform from './Transform/Transform'
import Layout from '../../Layout/Layout'

const HomePage = ({ className, ...rest }: HomePageProps) => {
  const { theme } = useApp()

  return (
    <Layout className={`${className}`} {...rest}>
      <Banner className="bg-primary-accent pt-8" />
      <Wave
        className="bg-primary-accent -mb-20"
        fill={theme.background.colors.base}
        height={10}
        numWaves={2}
      />
      <HowItWorks className="bg-base" />
      <Wave
        className="bg-base -mb-12"
        fill={theme.background.colors.accent}
        height={10}
        numWaves={5}
      />
      <BuildAnApp className="bg-accent" />
      <Wave
        className="bg-accent -mb-12"
        fill={theme.background.colors.base}
        height={20}
        numWaves={1}
      />
      <TestYourSkills className="bg-base" />
      <Wave
        className="bg-base -mb-12"
        fill={theme.background.colors.primary.accent}
        height={10}
        numWaves={4}
      />
      <Community className="bg-primary-accent" />
      <Stack className="bg-primary-accent pb-8" />
      <LearnToCode className="bg-base pt-12" />
      <MikeHug className="bg-base" />
      <Transform className="bg-accent" />
      <Price className="bg-base" />
    </Layout>
  )
}

export interface HomePageProps extends HtmlDiv {}

export default styled(observer(HomePage))``
