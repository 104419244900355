import React from 'react'
import styled from 'styled-components'

import { observer } from 'mobx-react-lite'
import Section from '../../../../Core/Layout/Section/Section'
import { HtmlDiv } from '../../../../../utils/types'
import SprintExampleLight from './SprintExampleLight/SprintExampleLight'
import SprintExampleDark from './SprintExampleDark/SprintExampleDark'
import ThemedImg from '../../../../utils/ThemedImg/ThemedImg'

const HowItWorks = ({ className, ...rest }: HowItWorksProps) => {
  return (
    <div className={`${className}`} {...rest}>
      <Section className="flex flex-wrap lg:justify-between justify-center items-center ">
        <div className="how-it-works">
          <div className="text-4xl font-bold">How it Works</div>
          <br />
          <div>
            Simulate what it’s like to work as a developer by completing coding
            sprints, tasks and building out a full stack web app
          </div>
          <br />
          <div className="flex lg:justify-around justify-start flex-wrap">
            <ul>
              <li>Fix bugs</li>
              <li>Build beautiful UIs</li>
              <li>Write tests</li>
              <li>Handle payments</li>
            </ul>
            <ul>
              <li>Refactor code</li>
              <li>Work with a database</li>
              <li>Build API endpoints</li>
              <li>Use git and version control</li>
            </ul>
          </div>
          <div>...and a whole lot more</div>
          <br />
          <div>All in your browser, all in a real codebase</div>
        </div>
        <ThemedImg
          sources={[
            { themeId: 'light', source: SprintExampleLight },
            { themeId: 'dark', source: SprintExampleDark },
          ]}
          className="flex-grow lg:mt-0 mt-8 sprint-example"
        />
      </Section>
    </div>
  )
}

export interface HowItWorksProps extends HtmlDiv {}

export default styled(observer(HowItWorks))`
  .how-it-works {
    max-width: 40rem;
  }
  .sprint-example {
    max-width: 25rem;
    min-width: 15rem;
  }
`
